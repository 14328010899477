<template>
  <Suspense>
    <div>
      <IdentitySetup />
      <div class="flex flex-col min-h-screen">
        <NuxtLayout>
          <NuxtPage
            :keepalive="{
              max: 2,
              include: ['events'],
            }" />
        </NuxtLayout>
        <Loader :show="loading" />
      </div>
    </div>
    <template #fallback>
      <Transition name="fade">
        <Splash />
      </Transition>
    </template>
  </Suspense>
</template>

<script setup lang="ts">
  import "animate.css";
  import dayjs from "dayjs";
  import "dayjs/locale/el";
  import localizedFormat from "dayjs/plugin/localizedFormat";
  import Vue3EasyDataTable from "vue3-easy-data-table";
  import "vue3-easy-data-table/dist/style.css";
  useNuxtApp().vueApp.component("EasyDataTable", Vue3EasyDataTable);

  const sessionStore = useSessionStore();
  const loading = ref(sessionStore.loading);

  sessionStore.$onAction(({ name, after }) => {
    if (name === "setLoading") {
      after(() => (loading.value = sessionStore.loading));
    }
  });

  const { locale } = useI18n();
  dayjs.extend(localizedFormat);
  dayjs.locale(locale.value);

  sessionStore.seti18nContent(
    await queryContent<MainContent>(
      `/${useI18n().getLocaleCookie()}/main`
    ).findOne()
  );
</script>

<style>
  body {
    @apply bg-gradient-to-r from-indigo-500/50 from-10% via-sky-500/60 via-30% to-emerald-500/40;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 2.5s;
  }
  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
