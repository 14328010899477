import { ref } from "vue";
import { defineStore } from "pinia";
import { type Auth, signOut as _signOut } from "firebase/auth";
import dayjs from "dayjs";
import { initializeApp, type FirebaseOptions } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import {
  connectStorageEmulator,
  getStorage,
  type FirebaseStorage,
} from "firebase/storage";
import {
  getFirestore,
  Firestore,
  connectFirestoreEmulator,
} from "firebase/firestore";
import type { DocContainer, Tenant } from "~/model";
import { doc, getDoc } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  type Functions,
} from "firebase/functions";
export interface EventInfo {
  name?: string;
  startTime?: string;
  endTime?: string;
  ownerUid: string;
  tenantId: string;
}

export const useSessionStore = defineStore("session", () => {
  const loading = ref(false);
  const _eventInfo = ref<EventInfo | null>();
  const eventInfo = computed(() => _eventInfo);
  const tenant = ref<DocContainer<Tenant>>();
  const eventId = ref<string>();
  const isTenantUser = ref(false);
  const isGuest = ref(true);
  const i18nContent = ref<Partial<MainContent>>({});
  const auth = ref<Auth>();
  const storage = ref<FirebaseStorage>();
  const db = ref<Firestore>();
  const funcs = ref<Functions>();

  const initialize = () => {
    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = <FirebaseOptions>{
      apiKey: "AIzaSyDJ2eCMBKQN4bnWk3KVwhsP19U1BXUnl4A",
      authDomain: "instabooth.app",
      projectId: "instabooth-app-9cc74",
      storageBucket: "instabooth-app-9cc74.appspot.com",
      messagingSenderId: "205248307672",
      appId: "1:205248307672:web:e1905bc79df4d495a852ad",
      measurementId: "G-1F1T4L3SBN",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    auth.value = getAuth(app);
    storage.value = getStorage(app);
    db.value = getFirestore(app);
    funcs.value = getFunctions(app, "europe-west3");

    auth.value.onAuthStateChanged(async () => {
      tenant.value = undefined;
      if (signedIn()) {
        isGuest.value =
          auth.value!.currentUser!.providerData[0].providerId != "password";
        const tenantId = await getTenantId();
        if (tenantId) {
          tenant.value = await fetchTenant(tenantId);
          isTenantUser.value = true;
        }
      }
    });

    const hostname = useRequestURL().hostname;
    const local = hostname === "localhost" || hostname.startsWith("192.168.");
    if (!local) {
      initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
          "6LeM2TIqAAAAAIGMw4CEBOfyQray3L0WN9otPpFN"
        ),
        isTokenAutoRefreshEnabled: true,
      });
    } else {
      connectAuthEmulator(auth.value!, "http://127.0.0.1:9099");
      connectStorageEmulator(storage.value!, "127.0.0.1", 9199);
      connectFirestoreEmulator(db.value, "127.0.0.1", 8080);
      connectFunctionsEmulator(funcs.value, "127.0.0.1", 5001);
    }
  };

  const eventUrl = computed(() => {
    const { locale } = useI18n();
    const url = useRequestURL();
    return `${url.protocol}//${url.host}/${locale.value}/booths/${eventId.value}`;
  });

  const signedIn = () =>
    (auth.value?.currentUser?.uid ?? "").length > 0 &&
    !auth.value!.currentUser!.isAnonymous;

  const signOut = async () => {
    if (!auth.value?.currentUser?.uid) return false;
    await _signOut(auth.value!);
    tenant.value = undefined;
  };

  const seti18nContent = async (content: MainContent) => {
    i18nContent.value = content;
  };

  const setEventName = async (name: string) => {
    eventId.value = name;
  };

  const setEventInfo = async (eventInfo?: EventInfo | null) => {
    _eventInfo.value = eventInfo;
  };

  const setLoading = (show: boolean) => {
    loading.value = show;
  };

  const isEventActive = () =>
    dayjs().isAfter(_eventInfo.value?.startTime) &&
    dayjs().isBefore(_eventInfo.value?.endTime);

  const isEventInTheFuture = computed(() =>
    dayjs().isBefore(_eventInfo.value?.startTime)
  );

  const isEventInThePast = computed(() =>
    dayjs().isAfter(_eventInfo.value?.endTime)
  );

  const fetchTenant = async (tenantId: string) => {
    const docRef = doc(db.value!, "tenants", tenantId);
    const docSnap = await getDoc(docRef);
    return <DocContainer<Tenant>>toDocData(docSnap);
  };

  return {
    eventName: eventId,
    tenant,
    setEventName,
    eventInfo,
    eventUrl,
    signedIn,
    loading,
    signOut,
    setLoading,
    setEventInfo,
    isEventActive,
    isEventInTheFuture,
    isEventInThePast,
    i18nContent,
    seti18nContent,
    initialize,
    auth,
    storage,
    db,
    funcs,
    isTenantUser,
    isGuest,
  };
});
